<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 justify-content-center">
      <div class="tbb-bg-image">
        <b-img
          :src="bgImage"
          alt="BeautiBook - Login"
        />
      </div>

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <tbb-logotype />
        <h2 class="text-white tbb-logo mb-0">
          <span class="font-weight-light">Beauti</span>Book
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center"
      >
        <div class="mx-auto text-center text-white mw-350">
          <b-card-title
            class="mb-1 font-weight-normal text-white"
            title-tag="h2"
          >
            Hey, it seems you are logging in to your account for the first time
          </b-card-title>
          <b-card-text class="mb-2">
            You need to provide your unique stylist/salon name before we can proceed. You will be able to update this later
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
          >
            <b-form
              v-if="!sending && !success"
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- stylistName -->
              <b-form-group
                label="Stylist name"
                label-for="register-stylistName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Stylist name"
                  vid="stylistName"
                  rules="required"
                  mode="aggressive"
                >
                  <b-form-input
                    id="register-stylistName"
                    v-model="stylistName"
                    name="register-stylistName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Your name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- stylistSubdomain -->
              <b-form-group
                label="Stylist subdomain"
                label-for="register-stylistSubdomain"
              >
                <validation-provider
                  #default="{ errors }"
                  ref="stylistSubdomain"
                  name="Stylist subdomain"
                  vid="stylistSubdomain"
                  rules="required"
                  mode="eager"
                >
                  <div class="d-flex">
                    <b-overlay
                      :show="subdomainStore.isPending"
                      rounded="sm"
                      variant="transparent"
                      spinner-variant="primary"
                      opacity="0.4"
                    >
                      <b-form-input
                        id="register-stylistSubdomain"
                        v-model="stylistSubdomain"
                        name="register-stylistSubdomain"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Your subdomain"
                        class="text-right"
                        @keyup="clearSubdomainStatusError()"
                      />
                    </b-overlay>
                    <span class="line-height-38px ml-03">.{{ domain }}</span>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="subdomainStore.isPending || subdomainStore.error || (subdomainStore.response != null && subdomainStore.response.status == false)"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>
        </div>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BButton, BForm, BFormGroup, BFormInput, BImg, BCardTitle, BCardText, BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import urlSlug from 'url-slug'
import { APPS_CALENDAR } from '@/router/routes/routes-path'
import { mapActions, mapMutations, mapState } from 'vuex'
import { AlertTriangleIcon } from 'vue-feather-icons'
import { TYPE } from 'vue-toastification'
import TbbLogotype from '../../sites/TbbLogotype.vue'
import TbbBg from '../../../assets/images/pages/tbb-bg.jpg'

export default {

  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BOverlay,
    TbbLogotype,
    // validations
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      sending: false,
      success: false,
      stylistName: null,
      stylistSubdomain: null,
      bgImage: TbbBg,
      // validation
      required,
      domain: process.env.VUE_APP_DOMAIN,
    }
  },

  computed: {
    ...mapState('RegisterStoreModule', {
      subdomainStore: state => state.subdomain,
    }),
  },
  watch: {
    subdomainStore: {
      deep: true,
      handler(newSubdomain) {
        if (newSubdomain.response) {
          const error = newSubdomain.response.status ? [] : ['The subdomain is in the use']
          this.$refs.stylistSubdomain.applyResult({
            errors: error,
            valid: !newSubdomain.response.status,
            failedRules: {},
          })
        }
        if (newSubdomain.error) {
          this.$toast('Something went wrong!', {
            icon: AlertTriangleIcon,
            type: TYPE.ERROR,
          })
        }
      },
    },
  },
  created() {
    this.$authStylist.initClient()
    if (!this.$auth.isAuthenticated) {
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    ...mapActions('RegisterStoreModule', [
      'fetchSubdomainStatus',
    ]),
    ...mapMutations('RegisterStoreModule', { clearSubdomainStatusError: 'CLEAR_SUBDOMAIN_STATUS_ERROR' }),
    getDefaultUserData() {
      const user = { ...this.$auth.user }

      return {
        email: user.email,
        name: user.given_name,
        lastname: user.family_name,
      }
    },
    async saveUserData() {
      const userData = {
        ...this.getDefaultUserData(),
        ...this.$auth.auth0UserData,
        businessName: this.stylistName,
        subdomain: this.stylistSubdomain,
      }

      await this.$api.patch(`/api/stylists/${this.$auth.stylistId}`, userData)

      this.$auth.setRegistered()

      return true
    },

    async register() {
      await this.checkSubdomainStatus()
      const success = await this.$refs.registerForm.validate()

      if (!success) {
        return
      }
      this.sending = true

      try {
        await this.saveUserData()
        this.success = true
      } catch (e) {
        this.$refs.registerForm.setErrors(e.message)
      } finally {
        this.sending = false
      }

      const subdomain = urlSlug(this.stylistSubdomain)
      await this.$authStylist.loadUserApiToken()
      this.$auth.cacheAuthData()
      const currentPort = typeof window.location.port !== 'undefined' ? `:${window.location.port}` : null
      let url = `https://${subdomain}.${this.$auth.currentDomain}`
      if (+currentPort !== 80) {
        url += `${currentPort}`
      }
      window.location.href = `${url}${APPS_CALENDAR}`
    },
    slugSubdomain() {
      this.stylistSubdomain = urlSlug(this.stylistSubdomain)
    },
    checkSubdomainStatus() {
      if (this.stylistSubdomain) {
        this.slugSubdomain()
        this.fetchSubdomainStatus(this.stylistSubdomain)
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import 'src/assets/scss/elements/login-page.scss';
.line-height-38px {
  line-height: 38px;
}

.ml-03 {
  margin-left: 0.3rem !important;
}
.auth-register-form label{
  color: #fff;
  text-align: left;
}
</style>
